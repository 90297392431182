<template>
	<div class="mt-lg-4">
		<div v-if="!addOrEditReady">
			<LoadingSpinner class="col-12" />
		</div>
		<div v-else>
			<button v-if="(!isLg && !isXl) && stallions_selected_local.length > 0" class="btn btn-primary btn-next" @click="sendStallions()">{{ $t('pax.visualiser_stallion') }} <font-awesome-icon :icon="['fal', 'long-arrow-right']" /></button>
			
			<div class="row align-items-center">
				<div class="col">
					<h3 class="text-nowrap">1. {{ $t('pax.les_etalons') }}</h3>
				</div>
				<div class="col-auto">
					<div class="input-group stallion_search">
					    <button class="input-group-text px-3" @click="openIntroduction"><font-awesome-icon :icon="['fas', 'info']" /></button>
					</div>
				</div>
				<div v-if="pax_express || (!pax_express && (isLg || isXl))" class="col-auto">
					<div v-if="pax_express" class="menu_flag"><img v-b-toggle.collapse-country :src="require(`GroomyRoot/assets/img/flag-iso/${country.iso}.png`)"></div>
					<div v-else class="menu_flag"><button @click="openFiltre"><img :src="require(`GroomyRoot/assets/img/flag-iso/${country.iso}.png`)"></button></div>
				</div>
				<div v-if="!pax_express" class="col-auto">
					<!-- <div class="input-group stallion_search"
					:class="{ opened: showStallionSearch }"
					>
					    <input type="text" class="form-control" v-model="search" placeholder="Rechercher un étalon"> 
					    <div class="input-group-append"
 						@click="showStallionSearch = !showStallionSearch"
					    >
					        <div class="input-group-text"><font-awesome-icon :icon="['far', 'search']" /></div>
					    </div>
					</div> -->

					<div class="input-group filter_button">
						<b-button class="input-group-text" @click="openFiltre">{{ $t('pax.modifier_filtres') }} <font-awesome-icon :icon="['fal', 'filter']" /></b-button>
					</div>
				</div>
			</div>
			<div class="row">
                <b-collapse id="collapse-country" class="col-12">
                	<e-select
						track-by="id"
						label="name"
						:options="countries_iso_array"
						:searchable="true"
						:allow-empty="false"
						:show-labels="false"
						:search="true"
						:placeholder="$t('global.search_country')"
						:deselectLabel="$t('global.selected_label')"
						:selectedLabel="$t('global.press_enter_to_select')"
						:selectLabel="$t('global.search_country')"
						v-model="country"
						@input="updateStallionFromCountry"
					>
			            <template slot="singleLabel" slot-scope="{ option }">
			                <img v-if="option.iso" :src="require(`GroomyRoot/assets/img/flag-iso/${option.iso}.png`)">
			                <span class="ml-2">{{ option.name }}</span>
			            </template>
			            <template slot="option" slot-scope="props">
			                <img v-if="props.option.iso" :src="require(`GroomyRoot/assets/img/flag-iso/${props.option.iso}.png`)">
			                <span class="ml-2">{{ props.option.name }}</span>
			            </template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</b-collapse>
			</div>
			<div class="row">
				<div class="col">
					<div class="paragraphes" v-if="pax_express">{{ $t('pax.text_stallions_infos_express') }}</div>
					<div class="paragraphes" v-else>{{ $t('pax.text_stallions_infos') }}</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<button 
						v-for="categorie in valid_categories"
						:key="categorie"
						@click="deleteCategorie(categorie)"
						class="btn btn-sm btn-primary rounded-pill mt-1 mr-2 mb-3"
					>
						{{ $t('pax.categories.'+categorie) }}
						<font-awesome-icon :icon="['fal', 'times']" />
					</button>
					<button 
						v-for="monte in valid_monte"
						:key="monte"
						@click="deleteTypeMonte(monte)"
						class="btn btn-sm btn-primary rounded-pill mt-1 mr-2 mb-3"
					>
						{{ $t('pax.type_monte.'+monte) }}
						<font-awesome-icon :icon="['fal', 'times']" />
					</button>
					<button 
						v-for="prix in valid_prix"
						:key="prix"
						@click="deletePrix(prix)"
						class="btn btn-sm btn-primary rounded-pill mt-1 mr-2 mb-3"
					>
						{{ $t('pax.prix_moins_de') }} {{ prix }} €
						<font-awesome-icon :icon="['fal', 'times']" />
					</button>
				</div>
			</div>
			<div class="row stallions-list">
				<div class="col-6" v-for="stallion in stallions_filtred" :key="stallion.horse_id" @click="selectStallion(stallion)" :class="{active: stallions_selected_local.find((elem) => { return elem.horse_id == stallion.horse_id }), 'col-lg-4' : step_label == 'stallions' || step_label == ''}">
					<div class="stallion">{{ stallion.horse_nom }}</div>
				</div>
			</div>
		</div>

		<b-modal dialog-class="modal-pax" ref="modal" hide-footer>
			<template v-slot:modal-title>
				<template v-if="pax_express">{{ $t('pax.pax_express') }}</template>
				<template v-else>{{ $t('pax.pax') }}</template>
			</template>

			<div v-html="text_intro"></div>
		</b-modal>

		<b-modal dialog-class="modal-pax modal-filtre" ref="modal-filtre" hide-footer>
			<template v-slot:modal-title>
				{{ $t('pax.filtrer_liste_etalons') }}
			</template>

			<label class="mb-0">{{ $t('pax.country') }}</label>
            <e-select
				track-by="id"
				label="name"
				:options="countries_iso_array"
				:searchable="true"
				:allow-empty="false"
				:show-labels="false"
				:search="true"
				:placeholder="$t('global.search_country')"
				:deselectLabel="$t('global.selected_label')"
				:selectedLabel="$t('global.press_enter_to_select')"
				:selectLabel="$t('global.search_country')"
				v-model="country"
				@change="validFiltreNextTick"
			>
	            <template slot="singleLabel" slot-scope="{ option }">
	                <img v-if="option.iso" :src="require(`GroomyRoot/assets/img/flag-iso/${option.iso}.png`)">
	                <span class="ml-2 text-secondary">{{ option.name }}</span>
	            </template>
	            <template slot="option" slot-scope="props">
	                <img v-if="props.option.iso" :src="require(`GroomyRoot/assets/img/flag-iso/${props.option.iso}.png`)">
	                <span class="ml-2 text-secondary">{{ props.option.name }}</span>
	            </template>
				<template slot="noOptions">{{ $t('global.list_empty') }}</template>
			</e-select>

			<div class="mt-2">{{ $t('pax.text_filter_country') }}</div>

			<label class="mt-4">{{ $t('pax.categories_etalon') }}</label>
			<div class="checkbox-container row">
				<div class="col-6"
				v-for="categorie in categories"
				:key="categorie.label"
				>
					<b-form-checkbox
						v-model="categorie.value"
						@change="changeCategorie(categorie)"
					>
						{{ $t('pax.categories.'+categorie.label) }} <font-awesome-icon :icon="['fal', 'times']" />
					</b-form-checkbox>
				</div>
				
			</div>

			<!-- <label class="mt-4">{{ $t('pax.type_monte_titre') }}</label>
			<div>
				<b-form-checkbox
					v-for="type in type_monte"
					v-model="type.value"
					:key="type.label"
				>
					{{ $t('pax.type_monte.'+type.label) }} <font-awesome-icon :icon="['fal', 'times']" />
				</b-form-checkbox>
			</div> -->


			<label class="mt-4">{{ $t('pax.prix') }}</label>
			<div class="checkbox-container row">
				<div class="col-6"
					v-for="prix in all_prix"
					:key="prix.label"
				>
					<b-form-checkbox
						v-model="prix.value"
						@change="validFiltreNextTick"
					>
						{{ $t('pax.prix_moins_de') }} {{ prix.label }} € <font-awesome-icon :icon="['fal', 'times']" />
					</b-form-checkbox>
				</div>
			</div>

			<div class="input-group filter_button">
				<b-button class="input-group-text mx-auto mt-4" @click="hideModalFiltre">{{ $t('pax.appliquer_filtre') }} <font-awesome-icon :icon="['fal', 'filter']" /></b-button>
			</div>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
    import { EventBus } from 'EventBus'
	import Navigation from "@/mixins/Navigation.js"
	import PaxMixin from "@/mixins/Pax.js"
	import _cloneDeep from 'lodash/cloneDeep';
	import _remove from 'lodash/remove';
	import Window from '@/mixins/Window.js'
	import Countries from '@/assets/lang/countries/countries'

	export default {
		name: "Stallions",
		props: {
			stallions_selected: {
				type: Array,
				default: () => ( [] )
			},
			stallions_available: {
				type: Array,
				default: () => ( [] )
			},
			step_label: {
				type: String,
				default: () => null
			},
			pax_express: {
				type: Boolean,
				default: () => false
			},
			stallions_preselected: {
				type: Array,
				default: () => ( [] )
			},
			filtre_categories: {
				type: Array,
				default: () => ( [] )
			},
			filtre_prix: {
				type: Array,
				default: () => ( [] )
			}
		},
		mixins: [Navigation, PaxMixin, Window],
		data () {
			return {
				addOrEditReady: false,
				search: '',
				stallions: [],
				stallions_selected_local: [],
				stallions_filtred: [],
				showStallionSearch : false,
				categories: [
					{label: 'FRAIS', value: false},
					{label: 'SOLIDARITE', value: false},
					{label: 'DRESSAGE', value: false},
					{label: 'CCE', value: false},
					{label: 'COLLECTION', value: false},
					{label: 'ETALONS CSO OU CCE', value: false}
				],
				type_monte: [
					{label: 'iac', 	value: false},
					{label: 'iart', value: false},
					{label: 'iarp', value: false},
					{label: 'mm', 	value: false},
					{label: 'mn', 	value: false},
					{label: 'iaf', 	value: false},
					{label: 'icsi', value: false},
					{label: 'vp', 	value: false}
				],
				all_prix: [
					{label: 1100, value: false},
					{label: 1500, value: false}
				],
				text_intro: '',
				countries_iso_array: [],
				country: null
			}
		},
		created() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.text_intro = this.getConfig('translations')['pax_introduction'][Vue.i18n.locale()]
				if(this.pax_express) {
					this.text_intro = this.getConfig('translations')['pax_express'][Vue.i18n.locale()]
				}
				this.addOrEditReady = false

				const country = this.getConfig('country')
				if(!country) {
					this.$emit("update:step_label", "country")
					return false
				}

				const countries = Countries[Vue.i18n.locale()].label
				const countries_iso = Countries[Vue.i18n.locale()].iso_3

				let countries_values = Object.values(countries)
				let countries_iso_values = Object.values(countries_iso)

				let temp = []
				for (let i = 0; i < countries_values.length; i++) {
					temp.push(countries_values[i])
				}

				let countries_iso_array_temp = []
				let countries_keys = Object.keys(countries)
				let countries_formatted = Object.fromEntries(countries_keys.map((_, i) => [countries_keys[i], temp[i]]))

				for (let [key, value] of Object.entries(countries_formatted)){
					countries_iso_array_temp.push({
						id: key,
						name: value,
						iso: countries_iso[key]
					})
				}

				const pax_countries = this.getConfig('pax_countries')
				if(pax_countries) {
					countries_iso_array_temp = countries_iso_array_temp.filter(c => {
						return pax_countries.includes(parseInt(c.id))
					})
				}

				this.countries_iso_array = countries_iso_array_temp.sort((a,b) => {
					let x = this.accentsTidy(a.name.toLowerCase());
				    let y = this.accentsTidy(b.name.toLowerCase());
				    return x < y ? -1 : x > y ? 1 : 0;
				});

				this.country = country

				const all_stallions = await this.getStallions()
				this.stallions = all_stallions
				this.stallions_filtred = _cloneDeep(this.stallions)

				if(this.filtre_categories.length > 0) {
					this.categories.forEach(cat => {
						if(this.filtre_categories.includes(cat.label)) {
							cat.value = true
						}
					})
				}
				else {
					this.categories[this.categories.findIndex(cat => cat.label == 'ETALONS CSO OU CCE')].value = true
				}

				if(this.filtre_prix.length > 0) {
					this.all_prix.forEach(prix => {
						if(this.filtre_prix.includes(prix.label.toString())) {
							prix.value = true
						}
					})
				}

				if(this.pax_express) {
					this.updateStallionFromCountry()
				}
				else {
					this.valideFiltre()
				}

				if(this.stallions_preselected.length > 0) {
					this.stallions_selected_local = this.stallions_filtred.filter(stallion => {
						return this.stallions_preselected.includes(stallion.horse_id)
					})
					this.sendStallions()
				}

				this.addOrEditReady = true
			},
			
			selectStallion(horse) {
				if(this.stallions_selected_local.find((elem) => { return elem.horse_id == horse.horse_id }) == undefined) {
					if((this.pax_express && this.stallions_selected_local.length < 2) || (!this.pax_express && this.stallions_selected_local.length < 10)) {
						this.stallions_selected_local.push(horse)
					}
				}
				else {
					const idx = this.stallions_selected_local.findIndex(stallion => stallion.horse_id == horse.horse_id)
					this.stallions_selected_local.splice(idx, 1)
				}

				if(this.stallions_selected_local.length == 0) {
					this.$emit("update:stallions_selected", [])
				}
			},

			sendStallions() {
				this.$emit("update:step_label", "stallions_selected")
				this.$emit("update:stallions_selected", _cloneDeep(this.stallions_selected_local))
			},

			openIntroduction() {
				this.$refs["modal"].show()
			},

			openFiltre() {
				this.$refs["modal-filtre"].show()
			},

			valideFiltre() {
				this.setConfig('country', this.country)

				this.$emit("update:filtre_categories", this.valid_categories)
				this.$emit("update:filtre_prix", this.valid_prix)

				// const has_collection = this.valid_categories.includes('COLLECTION')

				this.stallions_filtred = this.stallions.filter(stallion => {
					let has_cat = true
					this.valid_categories.forEach(cat => {
						if(!stallion.categorie.includes(cat)) {
							has_cat = false
						}
					})

					// si je n'ai pas la collection cochée mais que l'étalon à la collection alors je ne l'affiche pas
					// if(!has_collection && stallion.categorie.includes('COLLECTION')) {
					// 	has_cat = false
					// }

					if(this.valid_categories.length == 0 && (stallion.categorie.includes('COLLECTION') || stallion.categorie.includes('DRESSAGE'))) {
						has_cat = false
					}

					return has_cat && stallion.horse_pays.indexOf(parseInt(this.country.id)) > -1 && ((this.valid_prix.length == 1 && stallion.total <= this.valid_prix[0] && stallion.total !== 0) || (this.valid_prix.length === 0))

					// return (this.valid_categories.length > 0 && stallion.categorie.filter(categorie => this.valid_categories.includes(categorie)).length > 0 || (this.valid_categories.length == 0))
						// && (this.valid_monte.length > 0 && stallion.monte.filter(monte => this.valid_monte.includes(monte)).length > 0 || (this.valid_monte.length == 0))
						// && ((this.valid_prix && stallion.total <= this.valid_prix) || (this.valid_prix.length === 0))
				})

				this.$emit("update:stallions_available", this.stallions_filtred)
			},

			validFiltreNextTick() {
				this.$nextTick(() => {
					this.valideFiltre()
				})
			},

			hideModalFiltre() {
				this.$refs["modal-filtre"].hide()
			},

			deleteCategorie(categorie) {
				// s'il n'y a plus qu'une catégorie je ne permets pas de la supprimer
				if(this.valid_categories.length == 1) {
					return false
				}
				this.categories.find(a => a.label == categorie).value = false
				this.valideFiltre()
			},

			changeCategorie(categorie) {
				this.$nextTick(() => {
					// si on décoche une catégorie et qu'il n'y en a plus, je la recoche
					if(categorie.value == false && this.valid_categories.length == 0) {
						this.categories.find(a => a == categorie).value = true
					}
				})
				this.validFiltreNextTick()
			},

			deleteTypeMonte(monte) {
				this.type_monte.find(a => a.label == monte).value = false
				this.valideFiltre()
			},

			deletePrix(prix) {
				this.all_prix.find(a => a.label == prix).value = false
				this.valideFiltre()
			},

			updateStallionFromCountry() {
				this.setConfig('country', this.country)
				this.stallions_filtred = this.stallions.filter(stallion => stallion.horse_pays.indexOf(parseInt(this.country.id)) > -1)
			}
		},
		computed: {
			valid_categories() {
				return Object.values(this.categories.filter(cat => cat.value)).map(cat => cat.label)
			},
			valid_monte() {
				return Object.values(this.type_monte.filter(monte => monte.value)).map(monte => monte.label)
			},
			valid_prix() {
				return Object.values(this.all_prix.filter(prix => prix.value)).map(prix => prix.label)
			}
		},
		watch: {
			search(val) {
				this.stallions_filtred = this.stallions.filter( stallion => {
					return stallion.horse_nom.indexOf(val.toUpperCase()) > -1
				})
			},
			stallions_selected_local(val) {
				if(val.length > 0 && val !== this.stallions_selected && (this.isLg || this.isXl)) {
					this.sendStallions()
				}
			},
			'stallions_preselected': {
				deep: true,
				handler(val) {
					if(val.length > 0) {
						this.stallions_selected_local = this.stallions_filtred.filter(stallion => {
							return val.includes(stallion.horse_id)
						})
						this.sendStallions()
					}
				}
			},
			'stallions_selected': {
				deep: true,
				handler(val) {
					if(val.length > 0 && val.length !== this.stallions_selected_local) {
						this.stallions_selected_local = val
					}
				}
			}
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}
</script>
